import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet/dist/images/marker-shadow.png';

// Define custom icons
const customSourceIcon = new L.Icon({
  iconUrl: '../assets/images/marker-blue.png',
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  shadowUrl: 'leaflet/dist/images/marker-shadow.png',
  shadowSize: [41, 41], // Size of the shadow
});

const customDestinationIcon = new L.Icon({
  iconUrl: '../assets/images/marker-red.png',
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  shadowUrl: 'leaflet/dist/images/marker-shadow.png',
  shadowSize: [41, 41], // Size of the shadow
});

const RoutingMachine = ({ start, end, setFormData }) => {
  const map = useMap();
  const routingControlRef = useRef(null);

  useEffect(() => {
    if (!start || !end) return;

    if (routingControlRef.current) {
      routingControlRef.current.setWaypoints([L.latLng(start), L.latLng(end)]);
    } else {
      routingControlRef.current = L.Routing.control({
        waypoints: [L.latLng(start), L.latLng(end)],
        routeWhileDragging: false,
        addWaypoints: false,
        show: false,
      }).addTo(map);

      routingControlRef.current.on('routesfound', function (e) {
        const routes = e.routes;
        const summary = routes[0].summary;
        const distanceInMeters = summary.totalDistance;
        const distanceInKilometers = distanceInMeters / 1000; // Convert to kilometers
        setFormData(prevData => ({
          ...prevData,
          totalDistance: distanceInKilometers.toFixed(2) // Set the distance in formData
        }));
      });
    }

  }, [start, end, map, setFormData]);

  return null;
};

const LeafletMap = ({ mapHeight, center, zoom, hydrantMarkerPosition, destinationMarkerPosition, setFormData }) => {
  return (
    <div style={{ height: mapHeight }}>
      <MapContainer center={center} zoom={zoom} style={{ height: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {hydrantMarkerPosition && (
          <Marker position={hydrantMarkerPosition} icon={customSourceIcon}>
            <Popup>
              Source
            </Popup>
          </Marker>
        )}
        {destinationMarkerPosition && (
          <Marker position={destinationMarkerPosition} icon={customDestinationIcon}>
            <Popup>
              Destination
            </Popup>
          </Marker>
        )}
        {hydrantMarkerPosition && destinationMarkerPosition && (
          <RoutingMachine
            start={hydrantMarkerPosition}
            end={destinationMarkerPosition}
            setFormData={setFormData}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
